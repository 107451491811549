/* * Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__nav__menu {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* * Hamburger */
.hamburger {
  width: 50px;
  height: 50px;
}

.hamburger__btn{
  position: fixed;
  z-index: 100;
  width: 50px;  
  height: 50px;
  border-radius: 2px;
  background-color: white;
  box-shadow: 0 0 15px 2px rgba(36, 37, 67, 0.05);
  overflow: hidden;
  cursor: pointer;
}
.hamburger__btn:hover .hamburger__line {
  transform: translateX(-10px);
}
.hamburger__line{
  list-style: none;
  position: relative;
  transform: translateY(-50%);
  width: 50%;  height: 2px;
  background-color: var(--midnight-blue);
  transition: .5s ease;
}
.hamburger__line:nth-of-type(1){
  top: 30%;  left: 20%; 
  transition-delay: .7s; 
  opacity: 1; 
}
.hamburger__line:nth-of-type(2){
  top: 45%;  left: 30%; 
  transition-delay: 0s;   
}
.hamburger__line:nth-of-type(3){
  top: 60%;  left: 20%;
  transition-delay: .7s; 
  opacity: 1; 
}

.hamburger__btn.--active li:nth-of-type(1){
  left: 100%;
  transition-delay: 0s;
  opacity: 0; 
}
.hamburger__btn.--active li:nth-of-type(2){
  transform: translateY(-50%) rotate(315deg);
  transition-delay: .5s;
}
.hamburger__btn.--active li:nth-of-type(3){
  right: 100%;
  transition-delay: 0s;
  opacity: 0; 
}

.hamburger__line:nth-of-type(2)::before{
 content: '';
 position: absolute;
 top: -150px;  left: 0;
 width: 100%;  height: 100%;
 background: var(--midnight-blue);
 transform: rotate(90deg);
 transition: 1s;
 transition-delay: .5s;
}

.hamburger__btn.--active .hamburger__line:nth-of-type(2)::before{
 top: 0; 
 transition-delay: 1s;
}

.hamburger__menu {
  display: flex;
  opacity: 0;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 10rem 0;
  position: fixed;
  height: 100vh;
  z-index: 10;
  top: 0;
  right: -525px;
  /* background-color: rgb(18, 17, 17); */
  background-color: #181429;
  box-shadow: 0 0 30px 0 rgb(85, 85, 186, .4);
  width: 525px;
  height: 100%;
  transition: .5s ease; 
}

.hamburger__link{
  color: white;
  text-transform: capitalize;
  font-size: 4rem;
}

.--open {
  right: 0;
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .nav__link {
    /* display: none; */
  }
  .contact-us__btn {
    display: none; 
  }
}

@media screen and (max-width: 525px) {
  .hamburger__menu {
    width: 100%;
  }
}