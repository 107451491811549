.contact-us {
    position: relative;
    margin: auto;
    width: 60%;
}

.contact-us__form {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.contact-us__label {
    font-size: 1.2rem;
    color: #6a6a6a;
    text-align: start;
}

.form__item {
    text-align: start;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

.contact-us__input {
    padding: 2rem 1rem;
    font-size: 1.5rem;
    /* box-shadow: 0 0 10px 10px rgb(0, 0, 0, .5); */
    box-shadow: 0px 0px 50px 0px rgb(137, 137, 137, .1);
}

.contact-us__input:focus {
    border: 2px solid var(--cornflower-blue);
}

.contact-us__input::placeholder {
    font-size: 1.5rem;
    color: rgb(155, 155, 155);
}

#message {
    resize: vertical;
}


/* Budget menu */
.budget__menu {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.budget__item {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 3rem;
    box-shadow: 0 0 4px 0px rgba(130, 130, 130, 0.1);
    transition: var(--transition);
}

.budget__item p {
    font-size: 1.5rem;
    color: rgb(155, 155, 155);
}

.--selected {
    background-color: rgb(90, 91, 241, .3);
}

.--selected p {
    color: rgb(71, 71, 236);
}

@media screen and (max-width: 768px) {
    .contact-us {
        width: 90%;
    }
}