.main-home {
    z-index: 1;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* *Home background */
.home__bg {
    position: relative;
    z-index: -1;
    width: 100%;
    height: 100%;
}
.home__bg__line {
    position: relative;
    margin: 0;
    width: 100px;
    height: 3px;
    background-color: var(--cornflower-blue);
}

.home__bg__line:nth-child(1) {
    width: 500px;
    top: 240px;
    left: 50%;
    transform: translateX(-50%) rotate(90deg);
}
.home__bg__line:nth-child(2) {
    transform: rotate(90deg);
    width: 50%;
    top: 100px;
    left: 0%;
}
.home__bg__line:nth-child(3) {
    width: 30%;
    top: 40px;
    left: 0%;
}
.home__bg__line:nth-child(4) {
    width: 30%;
    top: 450px;
    left: 30%;
}

/* *Home title */
.home__title {
    line-height: 9rem;
    padding-left: 20px;
}
.home__title span {
    background: #fff;
    font-size: 6rem;
}
.home__title span:nth-child(1) {
    font-size: 7.5rem;
}

.line {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    width: 30px;
    height: 100%;
    background-color: var(--cornflower-blue);
}


/* *Home image */
.home__img {
    border-radius: 10px;
    box-shadow: 0 0 2rem 0 rgba(210, 174, 255, 0.4);
}

/* Mouse icon */
.mouse__container {
    position: relative;
    transition: var(--transition);
    animation: bounce 2s ease-in-out infinite;
}

@keyframes bounce {
    0% {top: -30px;}
    60% {top: 0px;}
    100% {top: -30px;}
}

@media screen and (max-width: 1440px) {
    .home__img {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .line {
        width: 20px;
    }
    .home__bg__line {
        display: none;
    }
    .home__title {
        padding-left: 0;
        line-height: 100px;
    }
    .home__title span {
        background: #fff;
        font-size: 4.5rem;
    }
    .home__title span:nth-child(1) {
        font-size: 5.5rem;
    }
}