.projects {
  position: relative;
}

.projects__top {
  position: "relative";
  margin: auto;
  width: 60%;
}

.lateral__img {
  position: absolute;
}

.projects__menu {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.project__item {
  position: relative;
  padding: 5rem 0;
  will-change: transform;
  background-color: transparent;
  transition: var(--transition);
  display: flex;
  justify-content: center;
  align-items: center;
}

.project__img {
  width: 80%;
  display: flex;
  justify-content: center;
  align-self: center;
  position: relative;
  margin: auto;
}

@media screen and (max-width: 1024px) {
  .lateral__img {
    display: none;
  }
  .project__img {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .projects__top {
    width: 100%;
  }

  .project__item {
    padding: 0;
  }
  .lateral__img {
    display: none;
  }
  .project__img {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
}
