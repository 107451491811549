/* * Footer */
.footer {
    position: relative;
    width: 100%;
    padding: 5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.footer__nav {
    padding: 10px 0;
}

.footer__nav__menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer__nav__item {
    margin-right: 4rem;
}

.footer__nav__link {
    text-transform: capitalize;
    font-size: 1.2rem;
    font-weight: 400;
}

/* * Footer socials */
.footer__socials__menu {
    display: flex;
}

.footer__socials__item {
    margin-left: 1rem;
    border-radius: 50%;
    padding: 5px;
    transition: var(--transition);
}

.footer__socials__item:hover {
    background-color: rgb(241, 246, 255, 0.8);
    fill: white;
}

.footer__socials__item a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(63, 63, 63);
}

/* *Footer info */
.footer__info {
    padding: 5px;
    transition: var(--transition);
}
.footer__info:hover {
    cursor: vertical-text;
    background-color: rgb(241, 246, 255, 0.8);
}

@media screen and (max-width: 768px) {
    .footer__nav {
        flex-direction: column;
    }
    .footer__nav__menu {
        display: none;
    }
    .logo {
        margin-bottom: 1rem;
    }
}