/* Importing fonts */
/* @import url("http://fonts.cdnfonts.com/css/sofia-pro"); */

@font-face {
  font-family: sofia-pro;
  src: url(https://use.typekit.net/af/0c5f71/00000000000000003b9b1aa0/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3)
      format("woff2"),
    url(https://use.typekit.net/af/0c5f71/00000000000000003b9b1aa0/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3)
      format("woff"),
    url(https://use.typekit.net/af/0c5f71/00000000000000003b9b1aa0/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3)
      format("opentype");
  font-weight: 900;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: sofia-pro;
  src: url(https://use.typekit.net/af/30420e/00000000000000003b9b1a9e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
      format("woff2"),
    url(https://use.typekit.net/af/30420e/00000000000000003b9b1a9e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
      format("woff"),
    url(https://use.typekit.net/af/30420e/00000000000000003b9b1a9e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
      format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: sofia-pro;
  src: url(https://use.typekit.net/af/8948c6/00000000000000003b9b1a9d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
      format("woff2"),
    url(https://use.typekit.net/af/8948c6/00000000000000003b9b1a9d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
      format("woff"),
    url(https://use.typekit.net/af/8948c6/00000000000000003b9b1a9d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
      format("opentype");
  font-weight: 700;
  font-style: italic;
  font-display: auto;
}
@font-face {
  font-family: sofia-pro;
  src: url(https://use.typekit.net/af/5855b2/00000000000000003b9b1a98/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
      format("woff2"),
    url(https://use.typekit.net/af/5855b2/00000000000000003b9b1a98/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
      format("woff"),
    url(https://use.typekit.net/af/5855b2/00000000000000003b9b1a98/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
      format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: sofia-pro;
  src: url(https://use.typekit.net/af/98f96f/00000000000000003b9b1a97/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
      format("woff2"),
    url(https://use.typekit.net/af/98f96f/00000000000000003b9b1a97/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
      format("woff"),
    url(https://use.typekit.net/af/98f96f/00000000000000003b9b1a97/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
      format("opentype");
  font-weight: 400;
  font-style: italic;
  font-display: auto;
}

@font-face {
  font-family: tk-sofia-pro-n9;
  src: url(https://use.typekit.net/af/0c5f71/00000000000000003b9b1aa0/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3)
      format("woff2"),
    url(https://use.typekit.net/af/0c5f71/00000000000000003b9b1aa0/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3)
      format("woff"),
    url(https://use.typekit.net/af/0c5f71/00000000000000003b9b1aa0/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3)
      format("opentype");
  font-weight: 900;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: tk-sofia-pro-n7;
  src: url(https://use.typekit.net/af/30420e/00000000000000003b9b1a9e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
      format("woff2"),
    url(https://use.typekit.net/af/30420e/00000000000000003b9b1a9e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
      format("woff"),
    url(https://use.typekit.net/af/30420e/00000000000000003b9b1a9e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
      format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: tk-sofia-pro-i7;
  src: url(https://use.typekit.net/af/8948c6/00000000000000003b9b1a9d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
      format("woff2"),
    url(https://use.typekit.net/af/8948c6/00000000000000003b9b1a9d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
      format("woff"),
    url(https://use.typekit.net/af/8948c6/00000000000000003b9b1a9d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
      format("opentype");
  font-weight: 700;
  font-style: italic;
  font-display: auto;
}
@font-face {
  font-family: tk-sofia-pro-n4;
  src: url(https://use.typekit.net/af/5855b2/00000000000000003b9b1a98/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
      format("woff2"),
    url(https://use.typekit.net/af/5855b2/00000000000000003b9b1a98/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
      format("woff"),
    url(https://use.typekit.net/af/5855b2/00000000000000003b9b1a98/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
      format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: tk-sofia-pro-i4;
  src: url(https://use.typekit.net/af/98f96f/00000000000000003b9b1a97/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
      format("woff2"),
    url(https://use.typekit.net/af/98f96f/00000000000000003b9b1a97/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
      format("woff"),
    url(https://use.typekit.net/af/98f96f/00000000000000003b9b1a97/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
      format("opentype");
  font-weight: 400;
  font-style: italic;
  font-display: auto;
}

:root {
  /* Colors */
  --cornflower-blue: #5a5bf1;
  --maya-blue: #51b9ef;
  --midnight-blue: #1a1b31;
  --athens-gray: #e7e7ee;
  --cristal-white: #fff;

  /* --font-color: var(--midnight-blue); */
  --font-color: var(var(--midnight-blue));

  --transition: 0.3s ease-in-out;
}

/* * Common */
#root {
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 14px;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: var(--font-color);
  letter-spacing: 0;
}

body {
  background-color: #fff;
  padding: 70px 240px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;  
  align-items: center;
}

header, main, section, footer {
  max-width: 1920px;
}

section,
main {
  position: relative;
  min-height: 90vh;
  margin-bottom: 6rem;
}

h1 {
  font-size: 7.5rem;
  letter-spacing: .5px;
}
h2 {
  font-size: 4rem;
}
h3 {
  font-size: 3rem;
}
h4 {
  font-size: 2rem;
}
ul {
  list-style: none;
}
div {
  background-color: transparent;
}
a {
  text-transform: uppercase;
  font-size: 1rem;
  text-decoration: none;
}
li:hover, a:hover {
  cursor: pointer;
}
label,
p {
  font-size: 1.1rem;
  line-height: 2rem;
}
small {
  font-size: 14px;
}
input,
textarea {
  background: transparent;
  border: none;
}
input:focus {
  outline: none;
}
button:focus,
input:focus,
textarea:focus {
  outline: none;
}
select {
  outline: none;
}

::-webkit-scrollbar {
  display: none;
}
::-moz-selection { /* Code for Firefox */
  color: var(--cristal-white);
  background: rgba(45, 35, 239, 0.4);
}
::selection {
  color: var(--cristal-white);
  background: rgba(45, 35, 239, 0.4);
}

button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 160px;
  height: 50px;
  padding: 1rem 4rem;
  border: none;
  border-radius: 5px;
  background: var(--cornflower-blue);
  transition: var(--transition);
  color: white;
  font-weight: bold;
  box-shadow: 0 0 30px 0 #5555ba66;
  z-index: 1;
}
button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border: none;
  border-radius: 5px;
  transition: transform 300ms ease-in-out;
  transform: scaleX(0);
  transform-origin: left;
  background-color: rgba(36, 37, 67, 0.2);
}
button:hover::before,
button:focus::before {
  transform: scaleX(1);
}
button:hover {
  transform: translateY(-10px);
}
button a {
  position: absolute;
  color: white;
  width: 100%;
}

svg {
  font-size: 1.5rem;
}

.bold {
  font-weight: 700;
}
.light {
  font-weight: 300;
}
.italic {
  font-style: italic;
}

.title {
  /* font-size: 7.5rem; */
}
.desc {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #2c2c33;
}

.nav {
  width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nav__link {
  transition: var(--transition);
  border-radius: 8px;
  padding: 5px;
}
.nav__link:hover {
  background-color: rgba(85, 85, 216, 0.1);
}


/* * SectionTop */
.section__desc__container {
  width: 400px;
}
.section__desc {
  border-left: 7px solid var(--cornflower-blue);
  padding-left: 10px;
}
.section__info {
  border-right: 10px solid var(--cornflower-blue);
  padding: 0 10px;
  margin: auto 0;
}

/* * Cursor */
.cursor {
  z-index: 1000;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background-color: var(--cornflower-blue);
  pointer-events: none;
  position: fixed;
  transform: scale(1.5);
  top: 0;
  left: 0;
  transition-duration: 200ms;
  transition-timing-function: ease-out;
  mix-blend-mode: difference;
}

/* * Logo */
.logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-radius: 1rem;
  transition: var(--transition);
}
.logo:hover {
  background-color: rgba(240, 245, 255, 0.8);
  box-shadow: 0 0 30px 0 rgb(85, 85, 186, .2);
}
.logo__img {
  border-radius: 1rem;
}
.logo__title {
  text-transform: lowercase;
  font-size: 1.5rem;
  font-weight: 400;
}

/* * Media queries */
@media screen and (max-width: 1440px) {
  body {
    padding: 50px 150px;
  }
  .cursor {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  body {
    padding: 50px 100px;
  }
}
@media screen and (max-width: 768px) {
  html.touch *:hover {
    all: unset !important;
  }
  html.touch *:focus {
    all: unset !important;
  }

  body {
    padding: 50px 40px;
  }
  section,
  main {
    min-height: 0;
  }
}

@media screen and (max-width: 450px) {
  .section__desc__container {
    width: 300px;
  }
  .section__info {
    display: none;
  }
  .section__desc {
    width: 300px;
  }
  h1 {
    font-size: 4.5rem;
  }
  h2 {
    font-size: 4rem;
  }
  h3 {
    font-size: 2rem;
  }
  h4 {
    font-size: 2rem;
  }
  small {
    font-size: 12px;
  }
}
