.need-help__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.need-help__title {
    color: var(--cornflower-blue);
}

.need-help__desc {
    text-align: center;
}

.need-help__btn {
    width: 250px;
    height: 80px;
}

.need-help__btn a p {
    font-size: 1.5rem;
    font-weight: bold;
}

@media screen and (max-width: 768px) {
    .need-help__title {
        font-size: 6rem;
    }
}
@media screen and (max-width: 450px) {
    .need-help__title {
        font-size: 4rem;
    }
}