.about {
    position: relative;
    margin: auto;
    width: 60%;
}


.offers__menu {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    height: 100%;
}

.offers__item {
    margin: 1rem .2rem;
    padding: .5rem 1rem;
    background: white;
    color: var(--midnight-blue);
    border: 1px solid rgb(210, 210, 210);
    border-radius: 3rem;
    text-align: center;
    align-self: center;
}

@media screen and (max-width: 768px) {
    .about {
        width: 100%;
    }
}