.popup {
  position: fixed;
  top: 30px;
  left: 50%;
  background: var(--cristal-white);
  transform: translate(-50%, 30px);
  transition: var(--transition);
  padding: 1rem 2rem;
  border-left: 3px solid rgb(0, 255, 166);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
  animation: show 200ms ease forwards;
}

@keyframes show {
  from {
    opacity: 0;
    top: -10px;
  }
  to {
      opacity: 1;
    top: 30px;
  }
}
